import BaseApiInstance from './base';
const BASE_URL = '/supporters';

export const getSupporterDetailApi = async id => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${id}`);
  return response;
};

export const updateSupporterDetailApi = async ({ supporterId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${supporterId}`,
    data,
  );
  return response;
};

export const transferMealApi = async ({ supporterId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${supporterId}/transfer-meal`,
    data,
  );
  return response;
};

export const deleteMealApi = async (supporterId, mealId) => {
  const response = await BaseApiInstance.delete(
    `${BASE_URL}/${supporterId}/meal/${mealId}`,
  );
  return response;
};

export const deleteSupporterApi = async id => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};

export const updateBoardingStatusApi = async ({ id, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/boarding-status`,
    data,
  );
  return response;
};
