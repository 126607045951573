/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';

function SEOTitle({ title, description }) {
  const titleWithPrefix = `${
    process.env.REACT_APP_IS_PROD_BUILD === 'true'
      ? 'Actively :'
      : 'Actively pre-prod :'
  } ${title || ''}`;
  return (
    <Helmet>
      <title>{titleWithPrefix}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}

export default memo(SEOTitle);
