import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the eventGroupDetail state domain
 */

const selectEventGroupDetailDomain = state =>
  state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by EventGroupDetail
 */

const makeSelectEventGroupDetail = () =>
  createSelector(selectEventGroupDetailDomain, substate => substate);

const makeSelectLoadingState = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.loadingState,
  );

const makeSelectError = () =>
  createSelector(selectEventGroupDetailDomain, substate => substate.error);

const makeSelectEventGroup = () =>
  createSelector(selectEventGroupDetailDomain, substate => substate.eventGroup);
const makeSelectEventGroupEvents = () =>
  createSelector(makeSelectEventGroup(), eventGroup =>
    eventGroup ? eventGroup.events || [] : [],
  );

const makeSelectStatsLoadingState = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.statsLoadingState,
  );
const makeSelectEvenGroupStats = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.eventGroupStatistic,
  );

const makeSelectConfirmedCounts = () =>
  createSelector(selectEventGroupDetailDomain, state => ({
    confirmedTeamCount: state.confirmedTeamCount,
    confirmedMemberCount: state.confirmedMemberCount,
    confirmedVolunteerCount: state.confirmedVolunteerCount,
    confirmedSupporterCount: state.confirmedSupporterCount,
    confirmedCompanyTeam: state.confirmedCompanyTeam,
  }));

const makeSelectBasicSettingState = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.basicSettingState,
  );

const makeSelectBasicSetting = () =>
  createSelector(makeSelectEventGroup(), eventDetail =>
    eventDetail
      ? {
          maxTeamSize: eventDetail.maxTeamSize,
          supporterRegLink: eventDetail.supporterRegLink,
          teamHostingLink: eventDetail.teamHostingLink,
          supporterHostingLink: eventDetail.supporterHostingLink,
        }
      : {},
  );

const makeSelectFormsLoadingState = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.formsLoadingState,
  );

const makeSelectLinkFormState = () =>
  createSelector(
    selectEventGroupDetailDomain,
    substate => substate.linkFormState,
  );

const makeSelectEventForms = () =>
  createSelector(selectEventGroupDetailDomain, substate => substate.forms);

const makeSelectEventGroupEndDate = () =>
  createSelector(makeSelectEventGroupEvents(), events =>
    events.reduce((maxDate, event) => {
      if (event.endDate) {
        const eventEndDate = new Date(event.endDate);
        return eventEndDate > maxDate ? eventEndDate : maxDate;
      }
      return maxDate;
    }, 0),
  );

export default makeSelectEventGroupDetail;
export {
  selectEventGroupDetailDomain,
  makeSelectLoadingState,
  makeSelectError,
  makeSelectEventGroup,
  makeSelectStatsLoadingState,
  makeSelectEvenGroupStats,
  makeSelectConfirmedCounts,
  makeSelectBasicSettingState,
  makeSelectBasicSetting,
  makeSelectFormsLoadingState,
  makeSelectLinkFormState,
  makeSelectEventForms,
  makeSelectEventGroupEvents,
  makeSelectEventGroupEndDate,
};
