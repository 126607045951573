import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_EVENT_GROUP_DETAIL,
  GET_EVENT_GROUP_STATS,
  GET_LINK_FORMS,
  LINK_FORM,
  UPDATE_EVENT_GROUP_BASIC_SETTING,
} from './constants';

import {
  getEventGroupDetailErrorAct,
  getEventGroupDetailSuccessAct,
  getEventGroupStatsErrorAct,
  getEventGroupStatsSuccessAct,
  getLinkFormsErrorAct,
  getLinkFormsSuccessAct,
  linkFormErrorAct,
  linkFormSuccessAct,
  updateEventGroupBasicSettingErrorAct,
  updateEventGroupBasicSettingSuccessAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import {
  getEventGroupApi,
  getEventGroupStatsApi,
  getEventGroupLinkFormsApi,
  updateEventGroupBasicSettingApi,
  updateLinkFormApi,
} from '../../api/event-group.api';

function* fetchEventGroup({ payload }) {
  try {
    const { data } = yield call(getEventGroupApi, payload);
    if (data) {
      yield put(getEventGroupDetailSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventGroupDetailErrorAct(errMessage));
  }
}

function* fetchEventGroupStats({ payload }) {
  try {
    const { data } = yield call(getEventGroupStatsApi, payload);
    if (data) {
      yield put(
        getEventGroupStatsSuccessAct({ ...data, ob: payload.params?.ob }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventGroupStatsErrorAct(errMessage));
  }
}

function* fetchEventGroupForms({ payload }) {
  try {
    const { data } = yield call(getEventGroupLinkFormsApi, payload);
    if (data) {
      yield put(getLinkFormsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getLinkFormsErrorAct(errMessage));
  }
}

function* updateFormLink({ payload }) {
  const { data: formData, oldValue } = payload;
  try {
    const { data } = yield call(updateLinkFormApi, payload);
    if (data) {
      yield put(
        linkFormSuccessAct({
          type: formData.type,
          id: data.id,
          title: data.title,
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      linkFormErrorAct({
        type: formData.type,
        error: errMessage,
        oldValue,
      }),
    );
  }
}
function* updateBasicSetting({ payload }) {
  try {
    const { data } = yield call(updateEventGroupBasicSettingApi, payload);
    if (data) {
      yield put(updateEventGroupBasicSettingSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateEventGroupBasicSettingErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* eventGroupDetailSaga() {
  yield takeLatest(GET_EVENT_GROUP_DETAIL, fetchEventGroup);
  yield takeLatest(GET_EVENT_GROUP_STATS, fetchEventGroupStats);
  yield takeEvery(GET_LINK_FORMS, fetchEventGroupForms);
  yield takeEvery(LINK_FORM, updateFormLink);
  yield takeLatest(UPDATE_EVENT_GROUP_BASIC_SETTING, updateBasicSetting);
}
