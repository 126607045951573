/*
 *
 * EventGroupDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getEventGroupDetailAct,
  getEventGroupDetailErrorAct,
  getEventGroupDetailSuccessAct,
  getEventGroupStatsAct,
  getEventGroupStatsErrorAct,
  getEventGroupStatsSuccessAct,
  getLinkFormsAct,
  getLinkFormsErrorAct,
  getLinkFormsSuccessAct,
  linkFormAct,
  linkFormErrorAct,
  linkFormSuccessAct,
  resetEventGroupDetailAct,
  resetFormLinkStateAct,
  resetGetEventGroupStatsAct,
  updateEventGroupBasicSettingAct,
  updateEventGroupBasicSettingErrorAct,
  updateEventGroupBasicSettingSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  eventGroup: null,
  eventGroupStatistic: null,
  statsLoadingState: 0,
  forms: null,
  formsLoadingState: 1,
  linkFormState: 0,
  basicSettingState: 0,
  supporterRegLinkState: 0,
  confirmedTeamCount: 0,
  confirmedMemberCount: 0,
  confirmedVolunteerCount: 0,
  confirmedSupporterCount: 0,
  confirmedCompanyTeam: 0,
};

/* eslint-disable default-case, no-param-reassign */
const eventGroupDetailSlice = createSlice({
  name: 'EventGroupDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventGroupDetailAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getEventGroupDetailErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventGroupDetailSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.eventGroup = payload;
      })
      .addCase(getEventGroupStatsAct, state => {
        state.statsLoadingState = 1;
        state.error = '';
      })
      .addCase(getEventGroupStatsErrorAct, (state, { payload }) => {
        state.statsLoadingState = 3;
        state.error = payload;
      })
      .addCase(getEventGroupStatsSuccessAct, (state, { payload }) => {
        state.statsLoadingState = 2;
        state.error = '';
        state.eventGroupStatistic = payload;
        if (payload.ob !== true) {
          const {
            teamCount,
            volunteerCount,
            memberCount,
            supporterCount,
            teamStats,
          } = payload;
          state.confirmedTeamCount = teamCount;
          state.confirmedMemberCount = memberCount;
          state.confirmedVolunteerCount = volunteerCount;
          state.confirmedSupporterCount = supporterCount;
          state.confirmedCompanyTeam = teamStats?.teamFromCompanyCount || 0;
        }
      })
      .addCase(getLinkFormsAct, state => {
        state.formsLoadingState = 1;
        state.error = '';
      })
      .addCase(getLinkFormsErrorAct, (state, { payload }) => {
        state.formsLoadingState = 3;
        state.error = payload;
      })
      .addCase(getLinkFormsSuccessAct, (state, { payload }) => {
        state.formsLoadingState = 2;
        state.error = '';
        state.forms = payload;
      })
      .addCase(updateEventGroupBasicSettingAct, state => {
        state.basicSettingState = 1;
        state.error = '';
      })
      .addCase(updateEventGroupBasicSettingErrorAct, (state, { payload }) => {
        state.basicSettingState = 3;
        state.error = payload;
      })
      .addCase(updateEventGroupBasicSettingSuccessAct, (state, { payload }) => {
        state.basicSettingState = 2;
        state.error = '';
        state.eventGroup = { ...state.eventGroup, ...payload };
      })
      .addCase(linkFormAct, (state, { payload }) => {
        const { data } = payload;
        switch (data.type) {
          case 'volunteer':
            if (state.forms) state.forms.volunteerForm = data;
            break;
          case 'supporter':
            if (state.forms) state.forms.supporterForm = data;
            break;
          case 'supportermeal':
            if (state.forms) state.forms.supporterMealForm = data;
            break;
          case 'teammeal':
            if (state.forms) state.forms.teamMealForm = data;
            break;
          case 'transport':
            if (state.forms) state.forms.transportForm = data;
            break;
          case 'thf':
            if (state.forms) state.forms.teamHostingForm = data;
            break;
          case 'shf':
            if (state.forms) state.forms.supporterHostingForm = data;
            break;
        }
        state.linkFormState = 1;
        state.error = '';
      })
      .addCase(linkFormErrorAct, (state, { payload }) => {
        const { error, oldValue, type } = payload;
        switch (type) {
          case 'volunteer':
            if (state.forms) state.forms.volunteerForm = oldValue;
            break;
          case 'supporter':
            if (state.forms) state.forms.supporterForm = oldValue;
            break;
          case 'supportermeal':
            if (state.forms) state.forms.supporterMealForm = oldValue;
            break;
          case 'teammeal':
            if (state.forms) state.forms.teamMealForm = oldValue;
            break;
          case 'transport':
            if (state.forms) state.forms.transportForm = oldValue;
            break;
          case 'thf':
            if (state.forms) state.forms.teamHostingForm = oldValue;
            break;
          case 'shf':
            if (state.forms) state.forms.supporterHostingForm = oldValue;
            break;
        }
        state.linkFormState = 3;
        state.error = error;
      })
      .addCase(linkFormSuccessAct, (state, { payload }) => {
        const { type, id, externalId, title } = payload;
        switch (type) {
          case 'volunteer':
            if (state.forms)
              state.forms.volunteerForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'supporter':
            if (state.forms)
              state.forms.supporterForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'supportermeal':
            if (state.forms)
              state.forms.supporterMealForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'teammeal':
            if (state.forms)
              state.forms.teamMealForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'transport':
            if (state.forms)
              state.forms.transportForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'thf':
            if (state.forms)
              state.forms.teamHostingForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'shf':
            if (state.forms)
              state.forms.supporterHostingForm = {
                id,
                externalId,
                title,
              };
            break;
        }
        state.linkFormState = 2;
        state.error = '';
      })
      .addCase(resetFormLinkStateAct, state => {
        state.linkFormState = 0;
        state.error = '';
        state.forms = null;
        state.basicSettingState = 0;
      })
      .addCase(resetGetEventGroupStatsAct, state => {
        state.statsLoadingState = 0;
        state.error = '';
        state.eventGroupStatistics = null;
      })
      .addCase(resetEventGroupDetailAct, () => initialState);
  },
});

export default eventGroupDetailSlice.reducer;
