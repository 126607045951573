import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_EVENT_MEMBER_LIST,
  GET_MEMBER_TAGS,
  GET_UNIQUE_COMPANY,
  GET_UNIQUE_COUNTRIES,
  GET_UNIQUE_SOURCE,
  GET_UNIQUE_ZIPCODES,
  UPDATE_MEMBER_DETAIL,
} from './constants';
import {
  getEventMemberListErrorAct,
  getEventMemberListSuccessAct,
  getMemberTagsErrorAct,
  getMemberTagsSuccessAct,
  getUniqueCompaniesErrorAct,
  getUniqueCompaniesSuccessAct,
  getUniqueCountriesErrorAct,
  getUniqueCountriesSuccessAct,
  getUniqueSourceErrorAct,
  getUniqueSourceSuccessAct,
  getUniqueZipCodesErrorAct,
  getUniqueZipCodesSuccessAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
} from './actions';
import {
  getEventMembersApi,
  getMemberUniqueCountriesApi,
  getMemberUniqueSourcesApi,
  getMemberUniqueZipCodesApi,
  getTeamUniqueCompaniesApi,
} from '../../api/event.api';
import { updateMemberApi } from '../../api/member.api';
import {
  getEvenGroupTeamUniqueCompaniesApi,
  getEventGroupMembersApi,
  getMemberUniqueCountriesByEventGroupApi,
  getMemberUniqueSourcesByEventGroupApi,
  getMemberUniqueZipCodesByEventGroupApi,
} from '../../api/event-group.api';
import { getErrorMessage } from '../../utils/error.util';
import { getMemberTagsApi } from '../../api/member-tag.api';

function* fetchEventMembers({ payload }) {
  try {
    // based on type in payload we need to call different api endpoints
    const { type, id, params } = payload;
    const api =
      type === 'event-group' ? getEventGroupMembersApi : getEventMembersApi;
    const { data } = yield call(api, id, params);
    if (data) {
      yield put(getEventMemberListSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventMemberListErrorAct(errMessage));
  }
}

function* fetchTags({ payload }) {
  try {
    const { data } = yield call(getMemberTagsApi, payload.params);
    if (data) {
      yield put(getMemberTagsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getMemberTagsErrorAct(errMessage));
  }
}

function* fetchUniqueZipCodes({ payload }) {
  try {
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getMemberUniqueZipCodesByEventGroupApi
        : getMemberUniqueZipCodesApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getUniqueZipCodesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueZipCodesErrorAct(errMessage));
  }
}

function* fetchUniqueCompanies({ payload }) {
  try {
    // based on type in payload we need to call different api endpoints
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getEvenGroupTeamUniqueCompaniesApi
        : getTeamUniqueCompaniesApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getUniqueCompaniesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueCompaniesErrorAct(errMessage));
  }
}

function* fetchUniqueSources({ payload }) {
  try {
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getMemberUniqueSourcesByEventGroupApi
        : getMemberUniqueSourcesApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getUniqueSourceSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueSourceErrorAct(errMessage));
  }
}

function* fetchUniqueCountries({ payload }) {
  try {
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getMemberUniqueCountriesByEventGroupApi
        : getMemberUniqueCountriesApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getUniqueCountriesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueCountriesErrorAct(errMessage));
  }
}

function* updateMemberDetail({ payload }) {
  try {
    const { data } = yield call(updateMemberApi, payload);
    if (data) {
      yield put(updateMemberDetailSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      updateMemberDetailErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

// Individual exports for testing
export default function* eventMemberListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_MEMBER_LIST, fetchEventMembers);
  yield takeLatest(GET_UNIQUE_ZIPCODES, fetchUniqueZipCodes);
  yield takeLatest(GET_UNIQUE_COMPANY, fetchUniqueCompanies);
  yield takeLatest(GET_UNIQUE_COUNTRIES, fetchUniqueCountries);
  yield takeLatest(GET_UNIQUE_SOURCE, fetchUniqueSources);
  yield takeLatest(GET_MEMBER_TAGS, fetchTags);
  yield takeEvery(UPDATE_MEMBER_DETAIL, updateMemberDetail);
}
