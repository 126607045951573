import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { DateBadge } from '../DateBadge';
import {
  makeSelectEventGroup,
  makeSelectEventGroupEndDate,
} from '../../managers/EventGroupDetailManager/selectors';

export function ActiveEventGroupInfo() {
  const eventGroupDetail = useSelector(makeSelectEventGroup());
  const endDate = useSelector(makeSelectEventGroupEndDate());
  if (eventGroupDetail) {
    return (
      <Box
        flexGrow="1"
        justifyContent="center"
        display="flex"
        gap={1}
        alignItems="stretch"
      >
        {endDate ? (
          <DateBadge date={eventGroupDetail.endDate}></DateBadge>
        ) : null}
        <Paper
          sx={{ p: 1, display: 'flex', alignItems: 'center' }}
          variant="outlined"
        >
          {eventGroupDetail.name}
        </Paper>
      </Box>
    );
  }
  return null;
}

export default memo(ActiveEventGroupInfo);
