import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_VOLUNTEER_TAGS,
  DELETE_VOLUNTEER_TAGS,
  GET_VOLUNTEER_TAGS_LIST,
  UPDATE_VOLUNTEER_TAGS,
} from './constants';

import {
  createVolunteerTagApi,
  deleteVolunteerTagApi,
  getVolunteerTagsApi,
  updateVolunteerTagApi,
} from '../../api/volunteer-tag.api';

import {
  getVolunteerTagsListError,
  getVolunteerTagsListSuccess,
  deleteVolunteerTagsError,
  deleteVolunteerTagsSuccess,
  updateVolunteerTagsSuccess,
  updateVolunteerTagsError,
  createVolunteerTagsError,
  createVolunteerTagsSuccess,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
function* fetchVolunteerTags({ payload }) {
  try {
    const { data } = yield call(getVolunteerTagsApi, payload.params);
    if (data) {
      yield put(getVolunteerTagsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getVolunteerTagsListError(errMessage));
  }
}

function* deleteVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteVolunteerTagApi, id);
    if (data) {
      yield put(deleteVolunteerTagsSuccess({ id }));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteVolunteerTagsError(errMessage));
  }
}

function* updateVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateVolunteerTagApi, id, payload.data);
    if (data) {
      yield put(
        updateVolunteerTagsSuccess({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateVolunteerTagsError(errMessage));
  }
}

function* createVolunteerTags({ payload }) {
  try {
    const { data } = yield call(createVolunteerTagApi, payload.data);
    if (data) {
      yield put(createVolunteerTagsSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createVolunteerTagsError(errMessage));
  }
}

// Individual exports for testing
export default function* volunteerTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_VOLUNTEER_TAGS_LIST, fetchVolunteerTags);
  yield takeEvery(DELETE_VOLUNTEER_TAGS, deleteVolunteerTags);
  yield takeEvery(UPDATE_VOLUNTEER_TAGS, updateVolunteerTags);
  yield takeEvery(CREATE_VOLUNTEER_TAGS, createVolunteerTags);
}
