/**
 *
 * Login
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  EmailOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Container,
} from '@mui/material';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoBg from '@images/login-bg.png';
import Logo from '@images/logo-with-text.png';

import {
  makeSelectLoginState,
  makeSelectLoginError,
} from '../../managers/LoginManager/selectors';
import messages from './messages';
import { loginAction } from '../../managers/LoginManager/actions';
import Seotitle from '../common/seotitle';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
});

const DEFAULT_ROUTE = process.env.REACT_APP_LANDING_URL || '/events/active';

export function Login() {
  // redux stores
  const loginState = useSelector(makeSelectLoginState());
  const loginError = useSelector(makeSelectLoginError());

  const loginSnackbarId = useRef();
  const redirectTimeout = useRef();

  const dispatch = useDispatch();
  const location = useLocation();

  const from = location.state?.from?.pathname || DEFAULT_ROUTE;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  // translations
  const intl = useIntl();
  const emailPlaceholder = intl.formatMessage(messages.email);
  const passwordPlaceholder = intl.formatMessage(messages.password);
  const signIn = intl.formatMessage(messages.signin);
  // const forgotPwd = intl.formatMessage(messages.forgotPwd);

  // state value
  const formState = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      dispatch(loginAction(values));
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loginError && loginState === 3) {
      loginSnackbarId.current = enqueueSnackbar(loginError, {
        variant: 'error',
      });
    } else if (loginSnackbarId.current && loginState !== 2) {
      closeSnackbar(loginSnackbarId.current);
    }
  }, [loginError, loginState]);

  useEffect(() => {
    if (loginState === 2) {
      loginSnackbarId.current = enqueueSnackbar(
        intl.formatMessage(messages.loginSuc),
        {
          variant: 'success',
        },
      );
      redirectTimeout.current = setTimeout(() => {
        navigate(from, { replace: true });
      }, 3000);
    }
  }, [loginState]);

  useEffect(() => () => {
    if (loginSnackbarId.current) {
      closeSnackbar(loginSnackbarId.current);
    }
    if (redirectTimeout.current) {
      clearTimeout(redirectTimeout.current);
    }
  });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <Seotitle title={signIn} description="Login in to the platform" />
      <Container sx={{ height: '100vh' }} maxWidth="lg">
        <Grid container sx={{ height: '100%', bgcolor: '#fff' }}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${LogoBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: t =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderBottomRightRadius: '10em',
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={1}
            square
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                p: 1,
              }}
            >
              <img src={Logo} alt="Logo" width={150} />
              <Typography component="h1" mt={2} variant="h5">
                {signIn}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={formState.handleSubmit}
                sx={{ mt: 1, maxWidth: '320px' }}
              >
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  value={formState.values.email}
                  onChange={formState.handleChange}
                  error={
                    formState.touched.email && Boolean(formState.errors.email)
                  }
                  variant="outlined"
                >
                  <InputLabel htmlFor="email">{emailPlaceholder}</InputLabel>
                  <OutlinedInput
                    id="email"
                    startAdornment={
                      <InputAdornment position="start" disablePointerEvents>
                        <IconButton
                          aria-label="Enter email address for login"
                          tabIndex={-1}
                        >
                          <EmailOutlined></EmailOutlined>
                        </IconButton>
                      </InputAdornment>
                    }
                    label={emailPlaceholder}
                    autoFocus
                  />
                  {formState.touched.email && (
                    <FormHelperText id="outlined-weight-helper-text">
                      {formState.errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  value={formState.values.password}
                  onChange={formState.handleChange}
                  error={
                    formState.touched.password &&
                    Boolean(formState.errors.password)
                  }
                  variant="outlined"
                >
                  <InputLabel htmlFor="password">
                    {passwordPlaceholder}
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    startAdornment={
                      <InputAdornment position="start" disablePointerEvents>
                        <IconButton
                          aria-label="Enter password for login"
                          edge="end"
                          tabIndex={-1}
                        >
                          <LockOutlined></LockOutlined>
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={passwordPlaceholder}
                    autoComplete="current-passworsd"
                  />
                  {formState.touched.password && (
                    <FormHelperText id="outlined-weight-helper-text">
                      {formState.errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={loginState === 1 || loginState === 2}
                >
                  {signIn}
                </Button>
                {/* <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Link href="/forgot-password">{forgotPwd}</Link>
                  </Grid>
                </Grid> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default memo(Login);
