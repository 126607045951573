import BaseApiInstance from './base';
const BASE_URL = '/event-groups';
export const getEventGroupListApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const getEventGroupApi = async id => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${id}`);
  return response;
};

export const createEventGroupApi = async data => {
  const response = await BaseApiInstance.post(`${BASE_URL}`, data);
  return response;
};

export const updateEventGroupApi = async ({ id, data }) => {
  const response = await BaseApiInstance.put(`${BASE_URL}/${id}`, data);
  return response;
};

export const deleteEventGroupApi = async ({ id }) => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};

export const getEventGroupStatsApi = async ({ id, params }) => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${id}/statistic`, {
    params,
  });
  return response;
};

export const getEventGroupLinkFormsApi = async id => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${id}/link-form`);
  return response;
};

export const updateLinkFormApi = async ({ eventId, data }) => {
  const response = await BaseApiInstance.post(
    `${BASE_URL}/${eventId}/link-form`,
    data,
  );
  return response;
};

export const updateEventGroupBasicSettingApi = async ({ id, data }) => {
  const response = await BaseApiInstance.put(`${BASE_URL}/${id}/setting`, data);
  return response;
};

export const getEventGroupTeamsApi = async (eventGroupId, params) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/teams`,
    {
      params,
    },
  );
  return response;
};

export const getEventGroupMembersApi = async (eventGroupId, params) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/members`,
    {
      params,
    },
  );
  return response;
};

export const getEventGroupSupportersApi = async ({ eventId, params }) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters`,
    {
      params,
    },
  );
  return response;
};

export const getSupporterUniqueZipCodesByEventGroupApi = async ({
  eventId,
}) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters/zip-codes`,
  );
  return response;
};

export const getSupporterUniqueCountriesByEventGroupApi = async ({
  eventId,
}) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters/countries`,
  );
  return response;
};

export const getEventGroupVolunteersApi = async (eventGroupId, params) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/volunteers`,
    {
      params,
    },
  );
  return response;
};

export const updateTeamSizeApi = async ({ eventId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${eventId}/team-size`,
    data,
  );
  return response;
};

export const updateSupporterRegLinkApi = async ({ eventId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${eventId}/supporter-registration-link`,
    data,
  );
  return response;
};

export const getEvenGroupTeamUniqueCompaniesApi = async id => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${id}/team/companies`,
  );
  return response;
};

export const getUsersAssignedToTeamByEventGroupApi = async eventGroupId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/resp-users`,
  );
  return response;
};

export const getMemberUniqueZipCodesByEventGroupApi = async eventGroupId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/member/zip-codes`,
  );
  return response;
};

export const getMemberUniqueSourcesByEventGroupApi = async eventGroupId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/member/sources`,
  );
  return response;
};

export const getMemberUniqueCountriesByEventGroupApi = async eventGroupId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventGroupId}/member/countries`,
  );
  return response;
};
