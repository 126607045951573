import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_EVENT_VOLUNTEER_LIST,
  GET_RESPONSIBLE_USERS,
  GET_UNIQUE_ZIPCODES,
  GET_VOLUNTEER_MISSIONS,
  GET_VOLUNTEER_TAGS,
} from './constants';
import { getEventVolunteersApi } from '../../api/event.api';
import {
  getEventVolunteerListError,
  getEventVolunteerListSuccess,
  getResponsibleUsersError,
  getResponsibleUsersSuccess,
  getUniqueZipCodesActError,
  getUniqueZipCodesActSuccess,
  getVolunteerMissionsError,
  getVolunteerMissionsSuccess,
  getVolunteerTagsError,
  getVolunteerTagsSuccess,
} from './actions';
import { getMissionsApi, getUniqueZipCodesApi } from '../../api/volunteer.api';
import { getUsersAssignedToVolunteer } from '../../api/user.api';
import { getEventGroupVolunteersApi } from '../../api/event-group.api';
import { getVolunteerTagsApi } from '../../api/volunteer-tag.api';

function* fetchVolunteers({ payload }) {
  try {
    const { id, params, type } = payload;
    const api =
      type === 'event-group'
        ? getEventGroupVolunteersApi
        : getEventVolunteersApi;
    const { data } = yield call(api, id, params);
    if (data) {
      yield put(getEventVolunteerListSuccess(data));
    } else {
      yield put(getEventVolunteerListError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventVolunteerListError(errMessage));
  }
}

function* fetchVolunteerMissions() {
  try {
    const { data } = yield call(getMissionsApi);
    if (data) {
      yield put(getVolunteerMissionsSuccess(data));
    } else {
      yield put(getVolunteerMissionsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getVolunteerMissionsError(errMessage));
  }
}

function* fetchResponsibleUsers({ payload }) {
  try {
    const { data } = yield call(getUsersAssignedToVolunteer, payload.id);
    if (data) {
      yield put(getResponsibleUsersSuccess(data));
    } else {
      yield put(getResponsibleUsersError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getResponsibleUsersError(errMessage));
  }
}

function* fetchTags({ payload }) {
  try {
    const { data } = yield call(getVolunteerTagsApi, payload.params);
    if (data) {
      yield put(getVolunteerTagsSuccess(data));
    } else {
      yield put(getVolunteerTagsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getVolunteerTagsError(errMessage));
  }
}

function* fetchUniqueZipCodes({ payload }) {
  try {
    const { data } = yield call(getUniqueZipCodesApi, payload.id);
    if (data) {
      yield put(getUniqueZipCodesActSuccess(data));
    } else {
      yield put(getUniqueZipCodesActError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueZipCodesActError(errMessage));
  }
}

// Individual exports for testing
export default function* eventTeamListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_VOLUNTEER_LIST, fetchVolunteers);
  yield takeLatest(GET_VOLUNTEER_MISSIONS, fetchVolunteerMissions);
  yield takeLatest(GET_RESPONSIBLE_USERS, fetchResponsibleUsers);
  yield takeLatest(GET_VOLUNTEER_TAGS, fetchTags);
  yield takeLatest(GET_UNIQUE_ZIPCODES, fetchUniqueZipCodes);
}
