/*
 *
 * EventGroup actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  CREATE_EVENT_GROUP,
  CREATE_EVENT_GROUP_ERROR,
  CREATE_EVENT_GROUP_SUCCESS,
  DELETE_EVENT_GROUP,
  DELETE_EVENT_GROUP_ERROR,
  DELETE_EVENT_GROUP_SUCCESS,
  GET_EVENT_GROUPS,
  GET_EVENT_GROUPS_ERROR,
  GET_EVENT_GROUPS_SUCCESS,
  GET_UNLINKED_EVENTS,
  GET_UNLINKED_EVENTS_ERROR,
  GET_UNLINKED_EVENTS_SUCCESS,
  RESET_EVENT_GROUP,
  RESET_UNLINKED_EVENTS_STATE,
  UPDATE_EVENT_GROUP,
  UPDATE_EVENT_GROUP_ERROR,
  UPDATE_EVENT_GROUP_SUCCESS,
} from './constants';

export const getEventGroupsAct = createAction(GET_EVENT_GROUPS);
export const getEventGroupsSuccessAct = createAction(GET_EVENT_GROUPS_SUCCESS);
export const getEventGroupsErrorAct = createAction(GET_EVENT_GROUPS_ERROR);

export const createEventGroupAct = createAction(CREATE_EVENT_GROUP);
export const createEventGroupSuccessAct = createAction(
  CREATE_EVENT_GROUP_SUCCESS,
);
export const createEventGroupErrorAct = createAction(CREATE_EVENT_GROUP_ERROR);

export const deleteEventGroupAct = createAction(DELETE_EVENT_GROUP);
export const deleteEventGroupSuccessAct = createAction(
  DELETE_EVENT_GROUP_SUCCESS,
);
export const deleteEventGroupErrorAct = createAction(DELETE_EVENT_GROUP_ERROR);

export const updateEventGroupAct = createAction(UPDATE_EVENT_GROUP);
export const updateEventGroupSuccessAct = createAction(
  UPDATE_EVENT_GROUP_SUCCESS,
);
export const updateEventGroupErrorAct = createAction(UPDATE_EVENT_GROUP_ERROR);

export const getUnlinkedEventsAct = createAction(GET_UNLINKED_EVENTS);
export const getUnlinkedEventsSuccessAct = createAction(
  GET_UNLINKED_EVENTS_SUCCESS,
);
export const getUnlinkedEventsErrorAct = createAction(
  GET_UNLINKED_EVENTS_ERROR,
);

export const resetUnlinkedEventsStateAct = createAction(
  RESET_UNLINKED_EVENTS_STATE,
);
export const resetEventGroupAct = createAction(RESET_EVENT_GROUP);
