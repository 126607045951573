import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  CREATE_EVENT_GROUP,
  DELETE_EVENT_GROUP,
  GET_EVENT_GROUPS,
  GET_UNLINKED_EVENTS,
  UPDATE_EVENT_GROUP,
} from './constants';
import {
  createEventGroupApi,
  deleteEventGroupApi,
  getEventGroupListApi,
  updateEventGroupApi,
} from '../../api/event-group.api';
import { getErrorMessage } from '../../utils/error.util';
import {
  createEventGroupErrorAct,
  createEventGroupSuccessAct,
  deleteEventGroupErrorAct,
  deleteEventGroupSuccessAct,
  getEventGroupsErrorAct,
  getEventGroupsSuccessAct,
  getUnlinkedEventsErrorAct,
  getUnlinkedEventsSuccessAct,
  resetUnlinkedEventsStateAct,
  updateEventGroupErrorAct,
  updateEventGroupSuccessAct,
} from './actions';
import { getUnlinkedEvents } from '../../api/event.api';

function* fetchEventGroups({ payload }) {
  try {
    const { data } = yield call(getEventGroupListApi, payload);
    if (data) {
      yield put(getEventGroupsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventGroupsErrorAct(errMessage));
  }
}

function* fetchUnlinkedEvents({ payload }) {
  try {
    const { data } = yield call(getUnlinkedEvents, payload);
    if (data) {
      yield put(getUnlinkedEventsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUnlinkedEventsErrorAct(errMessage));
  }
}

function* deleteEventGroup({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteEventGroupApi, payload);
    if (data) {
      yield put(deleteEventGroupSuccessAct({ id }));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);

    yield put(deleteEventGroupErrorAct(errMessage));
  }
}

function* updateEventGroup({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateEventGroupApi, payload);
    if (data) {
      yield put(
        updateEventGroupSuccessAct({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(resetUnlinkedEventsStateAct());
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateEventGroupErrorAct(errMessage));
  }
}

function* createEventGroup({ payload }) {
  try {
    const { data } = yield call(createEventGroupApi, payload);
    if (data) {
      yield put(createEventGroupSuccessAct());
      yield put(resetUnlinkedEventsStateAct());
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createEventGroupErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* TeamTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_GROUPS, fetchEventGroups);
  yield takeLatest(GET_UNLINKED_EVENTS, fetchUnlinkedEvents);
  yield takeEvery(DELETE_EVENT_GROUP, deleteEventGroup);
  yield takeEvery(UPDATE_EVENT_GROUP, updateEventGroup);
  yield takeEvery(CREATE_EVENT_GROUP, createEventGroup);
}
