/*
 *
 * EventGroup constants
 *
 */
export const REDUCER_KEY = 'EventGroup';

export const GET_EVENT_GROUPS = 'app/EventGroup/GET_EVENT_GROUPS';
export const GET_EVENT_GROUPS_ERROR = 'app/EventGroup/GET_EVENT_GROUPS_ERROR';
export const GET_EVENT_GROUPS_SUCCESS =
  'app/EventGroup/GET_EVENT_GROUPS_SUCCESS';

export const CREATE_EVENT_GROUP = 'app/EventGroup/CREATE_EVENT_GROUP';
export const CREATE_EVENT_GROUP_ERROR =
  'app/EventGroup/CREATE_EVENT_GROUP_ERROR';
export const CREATE_EVENT_GROUP_SUCCESS =
  'app/EventGroup/CREATE_EVENT_GROUP_SUCCESS';

export const UPDATE_EVENT_GROUP = 'app/EventGroup/UPDATE_EVENT_GROUP';
export const UPDATE_EVENT_GROUP_ERROR =
  'app/EventGroup/UPDATE_EVENT_GROUP_ERROR';
export const UPDATE_EVENT_GROUP_SUCCESS =
  'app/EventGroup/UPDATE_EVENT_GROUP_SUCCESS';

export const DELETE_EVENT_GROUP = 'app/EventGroup/DELETE_EVENT_GROUP';
export const DELETE_EVENT_GROUP_ERROR =
  'app/EventGroup/DELETE_EVENT_GROUP_ERROR';
export const DELETE_EVENT_GROUP_SUCCESS =
  'app/EventGroup/DELETE_EVENT_GROUP_SUCCESS';

export const GET_UNLINKED_EVENTS = 'app/EventGroup/GET_UNLINKED_EVENTS';
export const GET_UNLINKED_EVENTS_ERROR =
  'app/EventGroup/GET_UNLINKED_EVENTS_ERROR';
export const GET_UNLINKED_EVENTS_SUCCESS =
  'app/EventGroup/GET_UNLINKED_EVENTS_SUCCESS';

export const RESET_UNLINKED_EVENTS_STATE =
  'app/EventGroup/RESET_UNLINKED_EVENTS_STATE';
export const RESET_EVENT_GROUP = 'app/EventGroup/RESET_EVENT_GROUP';
