import axios from 'axios';
console.warn(process.env);
const BaseApiInstance = axios.create({
  baseURL: '/traily/api',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Api-Version': process.env.REACT_APP_API_VERSION
      ? process.env.REACT_APP_API_VERSION
      : '',
    'X-Tenant-Id': process.env.CLIENT,
  },
});

export default BaseApiInstance;
