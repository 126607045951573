import BaseApiInstance from './base';
const BASE_URL = '/volunteer-group';

export const getVolunteerGroupsApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const createVolunteerGroupApi = async data => {
  const response = await BaseApiInstance.post(`${BASE_URL}`, data);
  return response;
};

export const updateVolunteerGroupApi = async (id, data) => {
  const response = await BaseApiInstance.put(`${BASE_URL}/${id}`, data);
  return response;
};

export const deleteVolunteerGroupApi = async id => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};
