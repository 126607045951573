import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_EVENT, GET_EVENT_DETAIL } from './constants';
import { getEventApi, getEventDetailApi } from '../../api/event.api';
import {
  getEventDetailErrorAct,
  getEventDetailSuccessAct,
  getEventErrorAct,
  getEventSuccessAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';

function* fetchEvent({ payload }) {
  try {
    const { data } = yield call(getEventApi, payload);
    if (data) {
      yield put(getEventSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventErrorAct(errMessage));
  }
}

function* fetchEventDetail({ payload }) {
  try {
    const { data } = yield call(getEventDetailApi, payload);
    if (data) {
      yield put(getEventDetailSuccessAct({ ...data, ob: payload.params?.ob }));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventDetailErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* eventDetailSaga() {
  yield takeLatest(GET_EVENT, fetchEvent);
  yield takeLatest(GET_EVENT_DETAIL, fetchEventDetail);
}
