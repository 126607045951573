/*
 *
 * EventGroupDetail constants
 *
 */
export const REDUCER_KEY = 'EventGroupDetail';

export const GET_EVENT_GROUP_DETAIL =
  'app/EventGroupDetail/GET_EVENT_GROUP_DETAIL';
export const GET_EVENT_GROUP_DETAIL_ERROR =
  'app/EventGroupDetail/GET_EVENT_GROUP_DETAIL_ERROR';
export const GET_EVENT_GROUP_DETAIL_SUCCESS =
  'app/EventGroupDetail/GET_EVENT_GROUP_DETAIL_SUCCESS';

export const GET_EVENT_GROUP_STATS =
  'app/EventGroupDetail/GET_EVENT_GROUP_STATS';
export const GET_EVENT_GROUP_STATS_ERROR =
  'app/EventGroupDetail/GET_EVENT_GROUP_STATS_ERROR';
export const GET_EVENT_GROUP_STATS_SUCCESS =
  'app/EventGroupDetail/GET_EVENT_GROUP_STATS_SUCCESS';

export const GET_LINK_FORMS = 'app/EventGroupDetail/GET_LINK_FORMS';
export const GET_LINK_FORMS_ERROR = 'app/EventGroupDetail/GET_LINK_FORMS_ERROR';
export const GET_LINK_FORMS_SUCCESS =
  'app/EventGroupDetail/GET_LINK_FORMS_SUCCESS';

export const LINK_FORM = 'app/EventGroupDetail/LINK_FORM';
export const LINK_FORM_ERROR = 'app/EventGroupDetail/LINK_FORM_ERROR';
export const LINK_FORM_SUCCESS = 'app/EventGroupDetail/LINK_FORM_SUCCESS';

export const UPDATE_EVENT_GROUP_BASIC_SETTING =
  'app/EventGroupDetail/UPDATE_EVENT_GROUP_BASIC_SETTING';
export const UPDATE_EVENT_GROUP_BASIC_SETTING_ERROR =
  'app/EventGroupDetail/UPDATE_EVENT_GROUP_BASIC_SETTING_ERROR';
export const UPDATE_EVENT_GROUP_BASIC_SETTING_SUCCESS =
  'app/EventGroupDetail/UPDATE_EVENT_GROUP_BASIC_SETTING_SUCCESS';

export const RESET_EVENT_GROUP_DETAIL =
  'app/EventGroupDetail/RESET_EVENT_GROUP_DETAIL';
export const RESET_GET_EVENT_GROUP_STATS =
  'app/EventGroupDetail/RESET_GET_EVENT_GROUP_STATS';
export const RESET_LINK_FORM_STATE =
  'app/EventGroupDetail/RESET_LINK_FORM_STATE';
