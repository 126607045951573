import { AxiosError } from 'axios';
import BaseApiInstance from './base';

// import { REDUCER_KEY } from '../managers/AuthManager/constants';
import { sessionExpiredAction } from '../managers/AuthManager/actions';

const setupInterceptors = store => {
  BaseApiInstance.interceptors.request.use(
    config => {
      const requestConfig = config;
      // const { token } = store.getState()[REDUCER_KEY];
      // if (token) {
      //   requestConfig.headers.Authorization = token;
      // }
      return requestConfig;
    },
    error => Promise.reject(error),
  );

  BaseApiInstance.interceptors.response.use(
    response => {
      const { data } = response;
      if (data && data.code) {
        const message = `Request failed with status code ${response.status}`;
        const code = [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][
          Math.floor(response.status / 100) - 4
        ];
        const error = new AxiosError(
          message,
          code,
          response.config,
          response.request,
          response,
        );
        return Promise.reject(error);
      }
      return response;
    },
    error => {
      const { response } = error;
      if (response && response.status && response.status === 401) {
        // notify the session expiry action
        store.dispatch(sessionExpiredAction());
      }
      return Promise.reject(error);
    },
  );
};

export default setupInterceptors;
