/*
 *
 * EventDetail constants
 *
 */
export const REDUCER_KEY = 'EventDetail';

export const GET_EVENT = 'app/EventDetail/GET_EVENT';
export const GET_EVENT_ERROR = 'app/EventDetail/GET_EVENT_ERROR';
export const GET_EVENT_SUCCESS = 'app/EventDetail/GET_EVENT_SUCCESS';

export const GET_EVENT_DETAIL = 'app/EventDetail/GET_EVENT_DETAIL';
export const GET_EVENT_DETAIL_ERROR = 'app/EventDetail/GET_EVENT_DETAIL_ERROR';
export const GET_EVENT_DETAIL_SUCCESS =
  'app/EventDetail/GET_EVENT_DETAIL_SUCCESS';

export const RESET_GET_EVENT_STATS = 'app/EventDetail/RESET_GET_EVENT_STATS';
export const RESET_GET_EVENT_DETAIL = 'app/EventDetail/RESET_GET_EVENT_DETAIL';
