import BaseApiInstance from './base';
const BASE_URL = '/member-tag';

export const getMemberTagsApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const createMemberTagApi = async data => {
  const response = await BaseApiInstance.post(`${BASE_URL}/`, data);
  return response;
};

export const updateMemberTagApi = async (id, data) => {
  const response = await BaseApiInstance.put(`${BASE_URL}/${id}`, data);
  return response;
};

export const deleteMemberTagApi = async id => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};
