import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_TEAM_TAGS,
  DELETE_TEAM_TAGS,
  GET_TEAM_TAGS_LIST,
  UPDATE_TEAM_TAGS,
} from './constants';

import {
  getTeamTagsListError,
  getTeamTagsListSuccess,
  deleteTeamTagsError,
  deleteTeamTagsSuccess,
  updateTeamTagsSuccess,
  updateTeamTagsError,
  createTeamTagsError,
  createTeamTagsSuccess,
} from './actions';
import {
  createTeamTagApi,
  deleteTeamTagApi,
  getTeamTagsApi,
  updateTeamTagApi,
} from '../../api/team-tag.api';
import { getErrorMessage } from '../../utils/error.util';
function* fetchTeamTags({ payload }) {
  try {
    const { data } = yield call(getTeamTagsApi, payload.params);
    if (data) {
      yield put(getTeamTagsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getTeamTagsListError(errMessage));
  }
}

function* deleteTeamTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteTeamTagApi, id);
    if (data) {
      yield put(deleteTeamTagsSuccess({ id }));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteTeamTagsError(errMessage));
  }
}

function* updateTeamTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateTeamTagApi, id, payload.data);
    if (data) {
      yield put(
        updateTeamTagsSuccess({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateTeamTagsError(errMessage));
  }
}

function* createTeamTags({ payload }) {
  try {
    const { data } = yield call(createTeamTagApi, payload.data);
    if (data) {
      yield put(createTeamTagsSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createTeamTagsError(errMessage));
  }
}

// Individual exports for testing
export default function* TeamTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_TEAM_TAGS_LIST, fetchTeamTags);
  yield takeEvery(DELETE_TEAM_TAGS, deleteTeamTags);
  yield takeEvery(UPDATE_TEAM_TAGS, updateTeamTags);
  yield takeEvery(CREATE_TEAM_TAGS, createTeamTags);
}
