/* eslint-disable global-require */
/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const baseFRTranslationMessages = require('./translations/fr.json');
const baseENTranslationMessages = require('./translations/en.json');
const clientBrand = process.env.CLIENT;

const translationPath = clientBrand ? `/${clientBrand}` : '';

let clientENTranslationMessages = {};
let clientFRTranslationMessages = {};
try {
  clientFRTranslationMessages = require(
    `./translations${translationPath}/fr.json`,
  );
} catch (e) {
  clientFRTranslationMessages = {};
}

try {
  // eslint-disable-next-line global-require
  clientENTranslationMessages = require(
    `./translations${translationPath}/en.json`,
  );
} catch (e) {
  clientENTranslationMessages = {};
}

const frTranslationMessages = {
  ...baseFRTranslationMessages,
  ...clientFRTranslationMessages,
};
const enTranslationMessages = {
  ...baseENTranslationMessages,
  ...clientENTranslationMessages,
};

const DEFAULT_LOCALE = 'en';

// prettier-ignore
const appLocales = [
  'fr',
  'en',
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, frTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  fr: formatTranslationMessages('fr', frTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages),
};

// export {
//   appLocales,
//   formatTranslationMessages,
//   translationMessages,
//   DEFAULT_LOCALE,
// };

// uncomment while generating

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
