import axios from 'axios';
const BASE_URL = '/traily/api/auth';
const AuthApiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Api-Version': process.env.REACT_APP_API_VERSION
      ? process.env.REACT_APP_API_VERSION
      : '',
  },
});
export const loginApi = async payload => {
  const response = await AuthApiInstance.post(`login`, payload);
  return response;
};

export const logoutApi = async () => {
  const response = await AuthApiInstance.get(`logout`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  return response;
};
