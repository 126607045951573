import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_SUPPORTER_LIST,
  GET_UNIQUE_COUNTRIES,
  GET_UNIQUE_ZIPCODES,
  UPDATE_SUPPORTER,
} from './constants';
import {
  getSupporterListErrorAct,
  getSupporterListSuccessAct,
  getUniqueCountriesErrorAct,
  getUniqueCountriesSuccessAct,
  getUniqueZipCodesErrorAct,
  getUniqueZipCodesSuccessAct,
  updateSupporterDetailErrorAct,
  updateSupporterDetailSuccessAct,
} from './actions';
import {
  getEventSupportersApi,
  getSupporterUniqueZipCodesApi,
  getSupporterUniqueCountriesApi,
} from '../../api/event.api';
import {
  getTeamSupportersApi,
  getSupporterUniqueZipCodesApi as getTeamSupporterUniqueZipCodesApi,
  getSupporterUniqueCountriesApi as getTeamSupporterUniqueCountriesApi,
} from '../../api/team.api';
import { updateSupporterDetailApi } from '../../api/supporter.api';
import {
  getEventGroupSupportersApi,
  getSupporterUniqueCountriesByEventGroupApi,
  getSupporterUniqueZipCodesByEventGroupApi,
} from '../../api/event-group.api';
import { getErrorMessage } from '../../utils/error.util';

function* fetchSupporters({ payload }) {
  try {
    const { teamId, type } = payload;
    let apiToCall;
    if (teamId) {
      apiToCall = getTeamSupportersApi;
    } else {
      apiToCall =
        type === 'event-group'
          ? getEventGroupSupportersApi
          : getEventSupportersApi;
    }
    const { data } = yield call(apiToCall, payload);
    if (data) {
      yield put(getSupporterListSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getSupporterListErrorAct(errMessage));
  }
}

function* fetchUniqueZipCodes({ payload }) {
  try {
    const { teamId, type } = payload;
    let apiToCall;
    if (teamId) {
      apiToCall = getTeamSupporterUniqueZipCodesApi;
    } else {
      apiToCall =
        type === 'event-group'
          ? getSupporterUniqueZipCodesByEventGroupApi
          : getSupporterUniqueZipCodesApi;
    }
    const { data } = yield call(apiToCall, payload);
    if (data) {
      yield put(getUniqueZipCodesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueZipCodesErrorAct(errMessage));
  }
}

function* updateSupporterDetail({ payload }) {
  try {
    const { data } = yield call(updateSupporterDetailApi, payload);
    if (data) {
      yield put(updateSupporterDetailSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      updateSupporterDetailErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

function* fetchUniqueCountries({ payload }) {
  try {
    const { teamId, type } = payload;
    let apiToCall;
    if (teamId) {
      apiToCall = getTeamSupporterUniqueCountriesApi;
    } else {
      apiToCall =
        type === 'event-group'
          ? getSupporterUniqueCountriesByEventGroupApi
          : getSupporterUniqueCountriesApi;
    }
    const { data } = yield call(apiToCall, payload);
    if (data) {
      yield put(getUniqueCountriesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueCountriesErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* supporterListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_SUPPORTER_LIST, fetchSupporters);
  yield takeLatest(GET_UNIQUE_ZIPCODES, fetchUniqueZipCodes);
  yield takeLatest(GET_UNIQUE_COUNTRIES, fetchUniqueCountries);
  yield takeEvery(UPDATE_SUPPORTER, updateSupporterDetail);
}
