/*
 *
 * EventDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  resetGetEventDetail,
  getEventDetailAct,
  getEventDetailErrorAct,
  getEventDetailSuccessAct,
  getEventAct,
  getEventErrorAct,
  getEventSuccessAct,
  resetGetEventStatsAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  event: null,
  eventDetail: null,
  eventDetailLoadingState: 0,
  forms: null,
  formsLoadingState: 1,
  linkFormState: 0,
  basicSettingState: 0,
  supporterRegLinkState: 0,
  confirmedTeamCount: 0,
  confirmedMemberCount: 0,
  confirmedVolunteerCount: 0,
  confirmedSupporterCount: 0,
  confirmedCompanyTeam: 0,
};

/* eslint-disable default-case, no-param-reassign */
const eventDetailSlice = createSlice({
  name: 'EventDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getEventErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.event = payload;
      })
      .addCase(getEventDetailAct, state => {
        state.eventDetailLoadingState = 1;
        state.error = '';
      })
      .addCase(getEventDetailErrorAct, (state, { payload }) => {
        state.eventDetailLoadingState = 3;
        state.error = payload;
      })
      .addCase(getEventDetailSuccessAct, (state, { payload }) => {
        state.eventDetailLoadingState = 2;
        state.error = '';
        state.eventDetail = payload;
        if (payload.ob !== true) {
          const {
            teamCount,
            volunteerCount,
            memberCount,
            supporterCount,
            teamStats,
          } = payload;
          state.confirmedTeamCount = teamCount;
          state.confirmedMemberCount = memberCount;
          state.confirmedVolunteerCount = volunteerCount;
          state.confirmedSupporterCount = supporterCount;
          state.confirmedCompanyTeam = teamStats?.teamFromCompanyCount || 0;
        }
      })
      .addCase(resetGetEventStatsAct, state => {
        state.eventDetailLoadingState = 0;
        state.error = '';
        state.eventDetail = null;
      })
      .addCase(resetGetEventDetail, () => initialState);
  },
});

export default eventDetailSlice.reducer;
