/*
 *
 * EventDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_ERROR,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_ERROR,
  GET_EVENT_SUCCESS,
  RESET_GET_EVENT_DETAIL,
  RESET_GET_EVENT_STATS,
} from './constants';
export const getEventAct = createAction(GET_EVENT);
export const getEventErrorAct = createAction(GET_EVENT_ERROR);
export const getEventSuccessAct = createAction(GET_EVENT_SUCCESS);

export const getEventDetailAct = createAction(GET_EVENT_DETAIL);
export const getEventDetailErrorAct = createAction(GET_EVENT_DETAIL_ERROR);
export const getEventDetailSuccessAct = createAction(GET_EVENT_DETAIL_SUCCESS);

export const resetGetEventDetail = createAction(RESET_GET_EVENT_DETAIL);
export const resetGetEventStatsAct = createAction(RESET_GET_EVENT_STATS);
