/*
 *
 * EventGroupDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT_GROUP_DETAIL,
  GET_EVENT_GROUP_DETAIL_ERROR,
  GET_EVENT_GROUP_DETAIL_SUCCESS,
  GET_EVENT_GROUP_STATS,
  GET_EVENT_GROUP_STATS_ERROR,
  GET_EVENT_GROUP_STATS_SUCCESS,
  GET_LINK_FORMS,
  GET_LINK_FORMS_ERROR,
  GET_LINK_FORMS_SUCCESS,
  LINK_FORM,
  LINK_FORM_ERROR,
  LINK_FORM_SUCCESS,
  RESET_EVENT_GROUP_DETAIL,
  RESET_GET_EVENT_GROUP_STATS,
  RESET_LINK_FORM_STATE,
  UPDATE_EVENT_GROUP_BASIC_SETTING,
  UPDATE_EVENT_GROUP_BASIC_SETTING_ERROR,
  UPDATE_EVENT_GROUP_BASIC_SETTING_SUCCESS,
} from './constants';

export const getEventGroupDetailAct = createAction(GET_EVENT_GROUP_DETAIL);
export const getEventGroupDetailErrorAct = createAction(
  GET_EVENT_GROUP_DETAIL_ERROR,
);
export const getEventGroupDetailSuccessAct = createAction(
  GET_EVENT_GROUP_DETAIL_SUCCESS,
);

export const getEventGroupStatsAct = createAction(GET_EVENT_GROUP_STATS);
export const getEventGroupStatsErrorAct = createAction(
  GET_EVENT_GROUP_STATS_ERROR,
);
export const getEventGroupStatsSuccessAct = createAction(
  GET_EVENT_GROUP_STATS_SUCCESS,
);

export const getLinkFormsAct = createAction(GET_LINK_FORMS);
export const getLinkFormsErrorAct = createAction(GET_LINK_FORMS_ERROR);
export const getLinkFormsSuccessAct = createAction(GET_LINK_FORMS_SUCCESS);

export const linkFormAct = createAction(LINK_FORM);
export const linkFormErrorAct = createAction(LINK_FORM_ERROR);
export const linkFormSuccessAct = createAction(LINK_FORM_SUCCESS);

export const updateEventGroupBasicSettingAct = createAction(
  UPDATE_EVENT_GROUP_BASIC_SETTING,
);
export const updateEventGroupBasicSettingErrorAct = createAction(
  UPDATE_EVENT_GROUP_BASIC_SETTING_ERROR,
);
export const updateEventGroupBasicSettingSuccessAct = createAction(
  UPDATE_EVENT_GROUP_BASIC_SETTING_SUCCESS,
);

export const resetEventGroupDetailAct = createAction(RESET_EVENT_GROUP_DETAIL);
export const resetFormLinkStateAct = createAction(RESET_LINK_FORM_STATE);
export const resetGetEventGroupStatsAct = createAction(
  RESET_GET_EVENT_GROUP_STATS,
);
